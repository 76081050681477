var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('UserSkeleton')]},proxy:true}])},[(_vm.notFound)?_c('NotFound'):_c('div',[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"columns"},[(_vm.user && _vm.isMobile())?_c('div',{staticClass:"info-col"},[_c('UserCard',{attrs:{"userData":_vm.user}})],1):_vm._e(),_c('div',{staticClass:"main-col"},[_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"tabs-wrapper",class:{
                card: !_vm.isMobile(),
                'card-m': _vm.isMobile(),
              }},_vm._l((_vm.tabs),function(tab){return _c('b-button',{key:tab.title,attrs:{"disabled":_vm.tabDisabled(tab),"size":"sm","variant":("outline-" + (_vm.activeTab === tab.name && !tab.disabled
                      ? 'info'
                      : _vm.tabDisabled(tab)
                      ? 'dark'
                      : 'light'))},on:{"click":function($event){_vm.activeTab = tab.name}}},[_vm._v(" "+_vm._s(tab.title)+" "),(_vm.user[tab.prop])?_c('b-badge',{attrs:{"variant":_vm.activeTab === tab.name ? 'info' : 'light'}},[_vm._v(_vm._s(_vm.user[tab.prop])+" ")]):_vm._e()],1)}),1),_c(_vm.activeTab,{tag:"component"})],1),(!_vm.user.posts && !_vm.user.comments)?_c('b-container',{attrs:{"align":"center"}},[_c('b-img-lazy',{attrs:{"fluid":"","src":require("@/assets/img/empty.png")}}),_c('p',[_vm._v("It's so empty here...")])],1):_vm._e()],1),(_vm.user && !_vm.isMobile())?_c('div',{staticClass:"info-col"},[_c('UserCard',{attrs:{"userData":_vm.user}})],1):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }